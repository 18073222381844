@font-face {
  font-family: 'Inter';
  font-display: swap;
  src: url('assets/fonts/Inter-VariableFont_slnt-wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Space Grotesk';
  font-display: swap;
  src: url('assets/fonts/SpaceGrotesk-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'MBF Prime';
  font-display: swap;
  src: url('assets/fonts/MBF Prime.ttf') format('truetype');
}

/*@font-face {
  font-family: 'Stinger Fit';
  font-display: swap;
  src: url('./assets/fonts/AnyConv.com__1_Stinger-Regular.ttf') format('truetype'), url('./assets/fonts/AnyConv.com__1_Stinger-Bold.ttf') format('truetype');
}*/

html {
  font-size: 16px;
}

body {
  font-family: Inter;
  font-size: 1.125rem;
  background-color: #000;
  /*background-color: yellow;*/
  overflow-x: hidden;
  &.modal-active {
    height: 100vh;
    overflow-y: hidden;
  }
}


/** Layout
-------------------------------------------------------  */
.page {
  max-width: 1792px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;

  .container {
    margin: 0 auto;
    max-width: initial;
  }
}


.logo-wrap {
  & > * {
    display: inline-block;
    vertical-align: middle;
  }

  span {
    margin-left: 0.75rem;
    font-size: 1.1rem;
    font-weight: 600;
  }
}


/** Navigation
-------------------------------------------------------  */
.footer .nav {
  a:hover {
    opacity: 0.8;
    transition: all;
    transition-duration: 300ms;
  }
}

a.nav {
  cursor: pointer;
}

.header-nav {
  .connect-button {
    color: #ffffff;
  }

  a.nav {
    color: #ffffff;
    position: relative;
    @apply cursor-pointer;

    &:hover {
      visibility: visible;
      -webkit-transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform: scaleX(1);

      &:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        -ms-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
    &:active, &:focus {
      &:before {
        visibility: hidden;
      }
    }

    &:before {
      content: "";
      position: absolute;
      width: 90%;
      height: 2px;
      bottom: -1rem;
      left: 0;
      margin-left: 5%;
      background-color: #ffffff;
      visibility: hidden;
      -webkit-transform: scaleX(0);
      -ms-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transition: all 0.3s ease-in-out 0s;
      -o-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
    }

    &.connect-button:before {
      display: none;
    }
  }
}

.header-nav-modal .nav {
  font-size: 1.5rem;
  line-height: 1.5rem;
}

/** Buttons
-------------------------------------------------------  */
.btn {
  padding: 1rem 1.5rem;
  transition: background-color 300ms ease-in-out 0s;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  outline: none;

  &:hover {
    transition: all 300ms ease-in-out 0s;
  }

  &.btn-xs {
    padding: 0.3rem .6rem;
    font-size: 1rem;
    line-height: 1.1rem;
  }

  &.btn-sm {
    padding: 0.6rem 1.2rem;
    font-size: 1rem;
    line-height: 1.1rem;
  }

  &.btn-primary {
    background: linear-gradient(101.24deg, #308AD2 -25.44%, #CF53CF 64.78%, #FF007A 171.23%);
    box-shadow: 0px 2px 50px rgba(255, 27, 177, 0.35);
    border-radius: 5px;
  }

  &.btn-default {
    border: 2px solid #E63462;
    color: #ffffff;
    border-radius: 4px;
    outline: none;

    &:hover, &:focus {
      color: #EEEEEE;
      border: 2px solid #e00a42;

      &.red {
        color: #e00a42;
      }
    }

    &.red {
      color: #E63462;
    }
  }


  &.btn-white {
    border: 2px solid #ffffff;
    color: #ffffff;
    border-radius: 4px;
    outline: none;

    &:hover, &:focus {
      color: #EEEEEE;
      border: 2px solid #cccccc;

      &.gray {
        color: #595959;
        border-color: #595959;
      }
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    &.gray {
      color: #797979;
      border-color: #797979;
    }
  }

  &.btn-dark {
    background: #000000;
    color: #FFFFFF;

    &:hover, &:focus {
      background-color: #222222;
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;

      &:hover, &:focus {
        background-color: #222222;
      }
    }
  }
}

/** Full screen mode  */
.page.full-screen {
  #viewer-wrap {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 2;

    #viewer {
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
    }
  }

  .home-content {
    display: none;
  }
}


.help-block {
  display: block;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #666666;
  margin-bottom: 1rem;

  &.errors {
    margin-top: 1rem;
    color: #cc2200;
  }
}

.form {
  margin-top: 4rem;

  .form-col-1 {
    /*margin-right: 95px;*/
  }

  .form-row {
    margin-bottom: 2rem;

    .form-label {
      display: block;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1rem;
      color: #000000;
      margin-bottom: 0.625rem;

      &.required:after {
        content: '*';
        color: #D11149;
        margin-left: 5px;
      }
    }

    input, select {
      border-radius: 0.5rem;
      padding: 1rem 1.25rem;
      border: 1.5px solid #e6e6e6;
      outline: none;

      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1rem;
      margin-top: 0.5rem;

      &:hover, &:active, &:focus {
        border-color: #a6a6a6;
      }
    }

    select {
      padding: 0.875rem 1.25rem;
    }
  }
}


/** Notification message
------------------------------------------------------- */
.notification-info, .notification-success {
  background-color: #D054D0;
}

.notification-exit {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-exit.notification-exit-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}


/** Responsiveness
------------------------------------------------------- */

@media only screen and (max-width: 767px) {
  .page {
    background-image: url("./assets/images/body-bg-768.png");
    background-size: cover;
    max-width: 100%;
  }
}

@media only screen and (max-width: 639px) {
  .page {
    background-image: url("./assets/images/body-bg-768.png");
    background-size: cover;
    max-width: 100%;
  }
}

@media only screen and (max-width: 375px) {
  .page {
    background: url("./assets/images/body-bg-375.png") 0 -605px;
    background-size: cover;
  }
}

@media only screen and (min-width: 640px) {

}

@media only screen and (min-width: 768px) {
  .page {
    background-image: url("./assets/images/body-bg-768.png") 0 -605px;
    background-size: cover;
  }
}

@media only screen and (min-width: 1024px) {
  .page {
    background: url("./assets/images/body-bg-1024.png") 0 0px;
    background-size: cover;
  }
}

@media only screen and (min-width: 1280px) {
  .page {
    background: url("./assets/images/body-bg.png");
    background-size: cover;
  }
}

@media only screen and (min-width: 1792px) {
  .page {
    background-image: url("./assets/images/body-bg.png");
    background-size: cover;
  }
}
