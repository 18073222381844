.roadmap-item {
  position: relative;
}

@media screen and (min-width: 768px) {
  .img-mar {
    left: -20px;
    top: -550px;
    width: 1234px;
    height: 1468px;
    max-width: unset;
  }
  .img1 {
    left: 170px;
    top: -300px;
    width: 929px;
    height: 929px;
    max-width: unset;
  }
  .img2 {
    left: 170px;
    top: -300px;
    width: 929px;
    height: 929px;
    max-width: unset;
  }
  .img3 {
    left: 170px;
    top: -300px;
    width: 929px;
    height: 929px;
    max-width: unset;
  }
}

@media screen and (min-width: 1024px) {
  .img-mar {
    left: 80px;
    top: -550px;
    max-width: unset;
  }
  .img1 {
    left: 240px;
    top: -300px;
    width: 929px;
    height: 929px;
    max-width: unset;
  }
  .img2 {
    left: 350px;
    top: -300px;
    width: 929px;
    height: 929px;
    max-width: unset;
  }
  .img3 {
    left: 240px;
    top: -300px;
    width: 929px;
    height: 929px;
    max-width: unset;
  }
}

@media screen and (min-width: 1280px) {
  .img-mar {
    left: 220px;
    top: -550px;
    max-width: unset;
  }
  .img1 {
    left: 420px;
    top: -300px;
    width: 929px;
    height: 929px;
    max-width: unset;
  }
  .img2 {
    left: 520px;
    top: -300px;
    width: 929px;
    height: 929px;
    max-width: unset;
  }
  .img3 {
    left: 420px;
    top: -300px;
    width: 929px;
    height: 929px;
    max-width: unset;
  }
}

@media screen and (min-width: 1792px) {
  .img-mar {
    left: 400px;
    top: -550px;
    max-width: unset;
  }
  .img1 {
    left: 420px;
    top: -300px;
    width: 929px;
    height: 929px;
    max-width: unset;
  }
  .img2 {
    left: 620px;
    top: -300px;
    width: 929px;
    height: 929px;
    max-width: unset;
  }
  .img3 {
    left: 420px;
    top: -300px;
    width: 952px;
    height: 952px;
    max-width: unset;
  }
}
