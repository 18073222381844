/*
.btn {
    background-color: #F1A1E1;
    color: #181016;
    line-height: 18px;
}
.btn:hover{
    background-color: #f196df;
}

.btn-sm {
    padding: 14px 24px 14px 24px;
}

*/

.join-button {
    font-weight: 600 !important;
    &:hover, &:visited {
        background-color: #333333;
    }
}

.learn-more {
    font-weight: 600 !important;
    &:hover, &:visited {
        border: 1px solid #dddddd;
        background-color: #EEEEEE;
        color: #4558d2;
    }
}
