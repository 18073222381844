.moon {
  max-width: initial;
  left: 240px;
  top: -460px;
}

@media screen and (min-width: 768px) {
  .moon {
    left: 30rem;
    top: -12.5rem;
  }
  .home-top {
    padding-bottom: 12.5rem;

    .cards-wrap {
      position: absolute;
      bottom: -4rem;
      left: 21rem;
    }
  }
}

@media screen and (min-width: 1024px) {
  .moon {
    left: 620px;
    top: -240px;
  }

  .home-top {
    padding-bottom: unset;

    .cards-wrap {
      bottom: unset;
      top: 0;
      left: 32rem;
    }
  }
}

@media screen and (min-width: 1280px) {
  .moon {
    left: 720px;
    top: -240px;
  }

  .home-top {
    padding-bottom: unset;

    .cards-wrap {
      bottom: unset;
      top: 0;
      left: 32rem;
    }
  }
}

@media screen and (min-width: 1792px) {
  .moon {
    left: 800px;
    top: -240px;
  }

  .home-top {
    padding-bottom: unset;

    .cards-wrap {
      bottom: unset;
      top:0;
      left: 36rem;
    }
  }
}

