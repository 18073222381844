.space-pass-card {
  position: relative;
  /*backdrop-filter: blur(40px);*/
  box-shadow: 0px 0px 234px 0px rgb(208 83 208 / 30%);
  /*filter: drop-shadow(0px 0px 234px rgba(208, 83, 208, 0.3));*/

  .space-card-bg {
    z-index: -1;
  }
}


.card-logo {
  width: 55.77px;
  height: 16.6px;
}

@media only screen and (min-width: 640px) {
  .card-logo {
    width: 77px;
    height: 22px;
  }
}
