.input-wrap {
  .control {
    background: #EDEDED;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    @apply rounded-default;
    color: #737373;
    text-align: center;
    cursor: pointer;

    &:hover {
      background: #E3E3E3;
    }

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  input {
    outline: none;
    width: 60px;
    color: #000000;
    text-align: center;
    @apply rounded-default;

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}
